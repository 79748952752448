import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
			.get((process.env.VUE_APP_BACKEND || '') + '/api/users/', { params: {} })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
			.get((process.env.VUE_APP_BACKEND || '') + `/api/users/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroups(ctx) {
      return new Promise((resolve, reject) => {
        axios
			.get((process.env.VUE_APP_BACKEND || '') + `/api/group/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
		  .post((process.env.VUE_APP_BACKEND || '') + '/api/users/', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
		  .patch((process.env.VUE_APP_BACKEND || '') + '/api/users/'+userData.username+'/', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
		  .delete((process.env.VUE_APP_BACKEND || '') + '/api/users/'+userData.username+'/', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
