<template>
	<b-sidebar
		id="add-new-user-sidebar"
		:visible="isAddNewUserSidebarActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		right
		@hidden="resetForm"
		@change="val => $emit('update:is-add-new-user-sidebar-active', val)"
	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">Добавить пользователя</h5>

				<feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
			</div>

			<!-- BODY -->
			<validation-observer #default="{ handleSubmit }" ref="refFormObserver">
				<!-- Form -->
				<b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
					<!-- Username -->
					<validation-provider #default="validationContext" name="Username" rules="required|alpha-num">
						<b-form-group label="Логин" label-for="username">
							<b-form-input
								id="username"
								v-model="userData.username"
								:state="getValidationState(validationContext)"
								trim
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- First Name -->
					<validation-provider #default="validationContext" name="Имя" rules="required">
						<b-form-group label="Имя" label-for="first-name">
							<b-form-input
								id="first-name"
								v-model="userData.first_name"
								autofocus
								:state="getValidationState(validationContext)"
								trim
								placeholder="Имя"
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Last Name -->
					<validation-provider #default="validationContext" name="Имя" rules="required">
						<b-form-group label="Фамилия" label-for="last-name">
							<b-form-input
								id="last-name"
								v-model="userData.last_name"
								autofocus
								:state="getValidationState(validationContext)"
								trim
								placeholder="Фамилия"
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Email -->
					<validation-provider #default="validationContext" name="Email" rules="required|email">
						<b-form-group label="Email" label-for="email">
							<b-form-input
								id="email"
								v-model="userData.email"
								:state="getValidationState(validationContext)"
								trim
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Passwd -->
					<validation-provider #default="validationContext" name="Password" rules="required|password" vid="password">
						<b-form-group label="Пароль" label-for="password">
							<b-form-input
								id="password"
								v-model="userData.password"
								:state="getValidationState(validationContext)"
								trim
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Passwd -->
					<validation-provider #default="validationContext" name="Password" rules="required|confirmed:password">
						<b-form-group label="Пароль" label-for="password2">
							<b-form-input
								id="password2"
								v-model="userData.password2"
								:state="getValidationState(validationContext)"
								trim
							/>

							<b-form-invalid-feedback>
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- User Role -->
					<validation-provider #default="validationContext" name="Группа" rules="required">
						<b-form-group label="Группа" label-for="role-id" :state="getValidationState(validationContext)">
							<v-select
								v-model="userData.group_id"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="groups"
								:reduce="val => val.value"
								:clearable="false"
								input-id="role-id"
							/>
							<b-form-invalid-feedback :state="getValidationState(validationContext)">
								{{ validationContext.errors[0] }}
							</b-form-invalid-feedback>
						</b-form-group>
					</validation-provider>

					<!-- Form Actions -->
					<div class="d-flex mt-2">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							class="mr-2"
							type="submit"
						>
							Создать
						</b-button>
						<b-button
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
							type="button"
							variant="outline-secondary"
							@click="hide"
						>
							Отмена
						</b-button>
					</div>
				</b-form>
			</validation-observer>
		</template>
	</b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
	components: {
		BSidebar,
		BForm,
		BFormGroup,
		BFormInput,
		BFormInvalidFeedback,
		BButton,
		vSelect,

		// Form Validation
		ValidationProvider,
		ValidationObserver,
	},
	directives: {
		Ripple,
	},
	model: {
		prop: 'isAddNewUserSidebarActive',
		event: 'update:is-add-new-user-sidebar-active',
	},
	props: {
		isAddNewUserSidebarActive: {
			type: Boolean,
			required: true,
		},
		groups: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			required,
			alphaNum,
			email,
		}
	},
	setup(props, { emit }) {
		const blankUserData = {
			username: '',
			first_name: '',
			last_name: '',
			email: '',
			password: '',
			password2: '',
			mid_name: '',
			group_id: null,
		}

		const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
		const resetuserData = () => {
			userData.value = JSON.parse(JSON.stringify(blankUserData))
		}

		const onSubmit = () => {
			store.dispatch('app-user/addUser', userData.value).then(() => {
				emit('refetch-data')
				emit('update:is-add-new-user-sidebar-active', false)
			})
		}

		const { refFormObserver, getValidationState, resetForm } = formValidation(resetuserData)

		return {
			userData,
			onSubmit,

			refFormObserver,
			getValidationState,
			resetForm,
		}
	},
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
	.vs__dropdown-menu {
		max-height: 200px !important;
	}
}
</style>
